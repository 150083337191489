import Vue from 'vue';

import InvalidReportTypeError from '../errors/reporting/InvalidReportType';
import UnknownReportTypeError from '../errors/reporting/UnknownReportTypeError';

import { REPORT_EVENTS } from '../consts/eventTypes';

export const state = () => ({
  items: {},
  update: false,
  nextMajorGroupsReports: [],
  displayMode: false,
});

export const mutations = {
  setDisplayMode (state, displayMode) {
    state.displayMode = displayMode;
  },
  setNextMajorGroupsReports (state, Ids) {
    state.nextMajorGroupsReports = Ids;
  },
  clearReports (state) {
    state.items = {};
  },
  addItem (state, { report, typeId }) {
    const reportType = state.items[typeId];
    if (!reportType) {
      throw new UnknownReportTypeError(typeId);
    }
    reportType.Reports.push(report);
  },
  setType (state, type) {
    if (!type) {
      throw new InvalidReportTypeError(type);
    }
    Vue.set(state.items, type.Id, type);
  },
  updateType (state, { type, open }) {
    const item = state.items[type];
    if (item) {
      item.open = open;
    }
  },
  updateItem (state, { type, id, data }) {
    const reportTypeItem = state.items[type];
    if (reportTypeItem) {
      const report = reportTypeItem.Reports.find(x => x.Id === id);
      if (report) {
        if (data.object !== undefined && data.object.objectId !== undefined) {
          let detail = report.Details.find(x => x.objectId === data.object.objectId);

          if (detail) {
            if (data.object.detailId !== undefined && detail.Id !== data.object.detailId) {
              detail.Id = data.object.detailId;
            }
            if (data.object.Done !== undefined) {
              detail.Done = data.object.Done;
            }
            if (data.object.IsEmptyResult !== undefined) {
              detail.IsEmptyResult = data.object.IsEmptyResult;
            }
          } else {
            detail = report.Details.find(x => x.Id === data.object.detailId);
            if (data.object.Done !== undefined) {
              detail.Done = data.object.Done;
            }
          }
        }
        if (data.object !== undefined && data.object.id !== undefined) {
          const detail = report.Details.find(x => x.Id === data.object.id);
          if (detail) {
            if (data.object.image !== undefined) {
              detail.image = data.object.image;
            }
            if (data.object.Done !== undefined) {
              detail.Done = data.object.Done;
            }
            if (data.object.track !== undefined) {
              detail.track = data.object.track;
            }
            if (data.object.Bounds !== undefined) {
              detail.Bounds = data.object.Bounds;
            }
            if (data.object.Parkings !== undefined) {
              detail.Parkings = data.object.Parkings;
            }
            if (data.object.Stops !== undefined) {
              detail.Stops = data.object.Stops;
            }
            if (data.object.PointsCount !== undefined) {
              detail.PointsCount = data.object.PointsCount;
            }
          }
        }
        if (data.object !== undefined && report.after) {
          const detail = report.Details.find(x => (x.Id === data.object.id !== undefined ? data.object.id : false) || (data.object.objectId !== undefined ? x.objectId === data.object.objectId : false)) || {};
          if (detail && detail.Done) {
            report.after([detail, false, report.Id, report.Period, reportTypeItem.DisplayName, type]);
          }
        }

        if (data.Done !== undefined) {
          report.Done = data.Done;
        }
        if (data.open !== undefined) {
          report.open = data.open;
        }
        if (data.Period !== undefined) {
          report.Period = data.Period;
        }
        if (data.detailsDone !== undefined) {
          report.Details.forEach((_detail) => {
            const detail = _detail;
            detail.Done = data.detailsDone;
          });
        }
      }
    }
  },
  setTypes (state, items) {
    items.forEach((_item) => {
      if (_item.Id !== 32) {
        const item = _item;
        item.open = false;
        item.Reports = [];
        Vue.set(state.items, item.Id, item);
      }
    });
  },
  setItems (state, items) {
    items.forEach((_item) => {
      const item = _item;
      const type = state.items[item.ReportType];
      if (!type) {
        return;
      }
      type.ReportType = item.ReportType;

      const prepareDetails = (report) => {
        const extensions = { check: false, image: null, track: null };
        return report.Details.map(detail => ({ ...detail, ...extensions }));
      };
      const prepareReports = (reports) => {
        const extensions = { open: false, check: false };
        return reports.map(report => ({ ...report, ...extensions, Details: prepareDetails(report) }));
      };

      if (!type.Reports.length) {
        type.Reports = prepareReports(item.Reports);
      } else {
        const uniqueReports = item.Reports.filter((reportFromServer) => {
          const match = type.Reports.find(reportFromLocal => reportFromLocal.Id === reportFromServer.Id);
          return !match;
        });

        type.Reports = [...type.Reports, ...prepareReports(uniqueReports)];
      }
    });
  },
  deleteAllSummaryItems (state) {
    const types = Object.keys(state.items);
    types.forEach((_item) => {
      // eslint-disable-next-line
      const item = state.items[_item];
      item.Reports = [];
      item.open = false;
    });
  },
  deleteSummaryItem (state, { type, id }) {
    const item = state.items[type];
    if (item) {
      item.Reports = item.Reports.filter(x => x.Id !== id);
      if (item.Reports.length === 0) {
        item.open = false;
      }
    }
  },
  deleteDetailsItem (state, { type, id }) {
    const item = state.items[type];
    if (item) {
      let deleteR = null;
      item.Reports.forEach((_report) => {
        const report = _report;
        report.Details = report.Details.filter(x => x.Id !== id);
        if (report.Details.length === 0) {
          deleteR = _report.Id;
        }
      });
      if (deleteR) {
        item.Reports = item.Reports.filter(x => x.Id !== deleteR);
        if (item.Reports.length === 0) {
          item.open = false;
        }
      }
    }
  },
  checkItem (state, { type, id, value }) {
    const item = state.items[type];
    if (item) {
      const report = item.Reports.find(x => x.Id === id);
      if (report) {
        report.check = value;
        report.Details.forEach((_x) => {
          const x = _x;
          x.check = value;
        });
      }
    }
  },
  checkItemDetails (state, { type, id, detailId, value }) {
    const item = state.items[type];
    if (item) {
      const report = item.Reports.find(x => x.Id === id);
      if (report) {
        const detail = report.Details.find(x => x.Id === detailId);
        if (detail) {
          detail.check = value;
        }
        report.check = report.Details.filter(x => x.check).length === report.Details.length;
      }
    }
  },
  setUpdate (state, value) {
    state.update = value;
  },
};

export const getters = {
  nextMajorGroupsReports: state => state.nextMajorGroupsReports || [],
  reports: state => Object.values(state.items) || [],
  getReport: state => (id) => {
    const types = Object.values(state.items);
    // eslint-disable-next-line no-restricted-syntax
    for (const type of types) {
      const found = type.Reports.find(report => report.Id === id);
      if (found) {
        return found;
      }
    }
    return null;
  },
  getDetailReport: state => (id) => {
    const types = Object.values(state.items);
    // eslint-disable-next-line no-restricted-syntax
    for (const type of types) {
      // eslint-disable-next-line no-restricted-syntax
      for (const report of type.Reports) {
        const found = report.Details.find(detail => detail.Id === id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  },
  update: state => state.update,
  selectedReports: (state) => {
    let count = 0;
    Object.values(state.items).forEach((type) => {
      type.Reports.forEach((report) => {
        count += Number(report.check);
        report.Details.forEach((detail) => {
          count += Number(detail.check);
        });
      });
    });
    return count;
  },
};

export const actions = {
  // eslint-disable-next-line no-shadow
  initMQTT ({ dispatch, getters }) {
    global.$nuxt.$mqtt.on('message', async (_topic, message) => {
      const topic = _topic.split('/');
      const item = {
        id: topic[3],
        messageType: topic[2],
        controller: topic[1],
      };

      if (item.controller !== 'Reports') {
        return;
      }

      const raw = JSON.parse(message.toString());
      await dispatch('updateReportStatus', {
        id: raw.TaskId,
        detailId: raw.DetailId,
        objectId: raw.ObjectId,
        IsEmptyResult: raw.IsEmptyResult,
      });

      if (raw.State === 'Complete') {
        const summaryReport = getters.getReport(raw.TaskId);
        // console.debug('MQTT - REPORT message - COMPLETE', { topic, item, summaryReport, rawMessage: raw });

        summaryReport.Details.forEach((report) => {
          const data = [
            report,
            false, // summary,
            raw.TaskId, // summaryId
          ];
          this.$eventBus.notify(REPORT_EVENTS.reportIsCompleted, data);
        });
      }
    });
  },

  async getReports ({ commit }) {
    try {
      return await this.$api.$get('/v1/Reporting/Reports');
    } catch (error) {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения отчётов`,
        type: 'danger',
      }, { root: true });

      return null;
    }
  },

  async getReportTypes ({ commit }) {
    await this.$api.$get('/v1/Reporting/ReportTypes?$orderby=Id').then((data) => {
      if (data.Error === undefined) {
        commit('setTypes', data);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения типов отчётов`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  async createReport ({ dispatch, commit, state }, params) {
    try {
      const request = {
        ReportType: params.id,
        ObjectIds: params.objects.map(x => x.Id),
        Parameters: params.parameters,
      };
      const data = await this.$api.$post('/v1/Reporting/Create', request);

      if (data.Success) {
        const details = params.objects.map(obj => ({
          objectId: obj.Id,
          image: obj.image,
          track: null,
          Done: false,
          AccountingUnitName: obj.accountingUnitName ? obj.accountingUnitName : obj.AccountingUnit.Name,
          ObjectName: obj.Name,
          check: false,
        }));

        const report = {
          typeId: params.id,
          report: {
            Id: data.TaskId,
            Done: false,
            Period: params.period,
            CreateDate: new Date(),
            Details: details,
            open: true,
            check: false,
            after: params.after || null,
          },
        };

        try {
          commit('addItem', report);
        } catch (error) {
          if (error instanceof UnknownReportTypeError) {
            await dispatch('getReportTypes');
            commit('addItem', report);
          }
        }
        commit('updateType', { type: params.id, open: true });
      }

      if (!data.Success || data.Error !== null) {
        console.debug('store.reporting.createReport - fail create', { resData: data, paramsCreate: params });
        commit('notify/addNotify', {
          massage: `Ошибка создания отчета: ${data.Error}`,
          type: 'danger',
        }, { root: true });
      }
    } catch (error) {
      console.error('store.reporting.createReport - error createReport', error);
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания отчёта`,
        type: 'danger',
      }, { root: true });
    }

    return true;
  },

  getReportDataSummary ({ state, commit, dispatch }, id) {
    return this.$api.$get(`/v1/Reporting/Summary/${id}`)
      .then((data) => {
        if (data) {
          try {
            dispatch('tracking/track', {
              idOrType: 'OpenReport',
              reportText: `Общий отчет. ${data.Data.ReportTitle} за период с ${new Date(data.Data.PeriodBegin * 1000).toLocaleString('ru', { timeZone: 'UTC' })} по ${new Date(data.Data.PeriodEnd * 1000).toLocaleString('ru', { timeZone: 'UTC' })}`,
            }, { root: true });
          // eslint-disable-next-line no-empty
          } catch {}
          return data;
        }
        return null;
      })
      .catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения объединенного отчёта ${id}`,
          type: 'danger',
        }, { root: true });
        return null;
      });
  },

  getReportDataDetail ({ commit, dispatch }, id) {
    return this.$api.$get(`/v1/Reporting/Detail/${id}`).then((data) => {
      if (data && data.State !== 'Waiting') {
        try {
          dispatch('tracking/track', {
            idOrType: 'OpenReport',
            reportText: `[${data.Data.ObjectName}] Полный отчет. ${data.Data.ReportTitle} за период с ${new Date(data.Data.PeriodBegin * 1000).toLocaleString('ru', { timeZone: 'UTC' })} по ${new Date(data.Data.PeriodEnd * 1000).toLocaleString('ru', { timeZone: 'UTC' })}`,
          }, { root: true });
        // eslint-disable-next-line no-empty
        } catch {}
        return data;
      }
      if (data && data.State === 'Waiting') {
        console.warn(`Report "${id}" not ready yet. Waiting`);
      }
      return null;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения детального отчёта ${id}`,
        type: 'danger',
      }, { root: true });
      return null;
    });
  },

  deleteAllReports ({ commit }) {
    return this.$api.delete('/v1/Reporting/All').then(() => {
      commit('deleteAllSummaryItems');
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления всех отчётов`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteReportSummary ({ commit }, { type, id }) {
    try {
      commit('deleteSummaryItem', { type, id });
      this.$api.delete(`/v1/Reporting/Summary/${id}`);
    } catch (error) {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления объединенного отчёта ${id}`,
        type: 'danger',
      }, { root: true });
    }
  },

  deleteReportDetails ({ commit }, { type, id }) {
    try {
      commit('deleteDetailsItem', { type, id });
      this.$api.delete(`/v1/Reporting/Detail/${id}`);
    } catch (error) {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления детального отчёта ${id}`,
        type: 'danger',
      }, { root: true });
    }
  },

  rebuildReportSummary ({ commit }, { type, id }) {
    return this.$api.put(`/v1/Reporting/Summary/${id}`).then(() => {
      commit('updateItem', { type, id, data: { Done: true, detailsDone: true } });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка обновления объединенного отчёта`,
        type: 'danger',
      }, { root: true });
    });
  },

  rebuildReportDetails ({ commit }, { type, id, detailId }) {
    return this.$api.put(`/v1/Reporting/Detail/${detailId}`).then(() => {
      commit('updateItem', { type, id, data: { Done: true, object: { Done: true, id: detailId } } });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка обновления детального отчёта`,
        type: 'danger',
      }, { root: true });
    });
  },

  async updateReportStatus ({ state, dispatch, commit }, { id, detailId = null, objectId = null, IsEmptyResult = undefined }) {
    const types = Object.values(state.items);
    const type = types.find(x => x.Reports.find(r => r.Id === id));
    if (type === undefined) {
      const allReports = await dispatch('getReports'); // запрос получения отчётов от апи
      const found = allReports.find(x => x.Reports.find(r => r.Id === id));
      if (!found) { throw new UnknownReportTypeError(); }

      await commit('setType', found);
      await commit('setUpdate', true);

      // в случае, если интернет медленный, и сообщение из mqtt приходит быстрее чем ответ от апи, то нужно подождать
      // пока отчёт не появится в списке отчётов и снова вызвать этот метод
      if (found.Reports.find(r => r.Id === id).Details.length > 0) {
        setTimeout(() => {
          dispatch('updateReportStatus', { id, detailId, objectId, IsEmptyResult });
        }, 1000);
      }
    } else if (detailId === null) {
      commit('updateItem', { type: type.Id, id, data: { Done: true } });
    } else {
      commit('updateItem', { type: type.Id, id, data: { object: { Done: true, objectId, detailId, IsEmptyResult } } });
    }
  },

  getSchedule ({ commit }) {
    return this.$api.$get('/v1/Reporting/Schedules').then(data => data).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения расписаний отчётов}`,
        type: 'danger',
      }, { root: true });
    });
  },

  getScheduleId ({ commit }, id) {
    return this.$api.$get(`/v1/Reporting/Schedules/${id}`).then(data => data).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения расписаний отчётов}`,
        type: 'danger',
      }, { root: true });
      return {};
    });
  },

  async createReportSchedule ({ commit }, params) {
    await this.$api.$post('/v1/Reporting/Schedule', {
      ReportType: params.id,
      ObjectIds: params.objects,
      Parameters: params.parameters,
      AccountingUnitId: 0,
      PeriodType: params.periodType,
      PeriodValue: params.periodValue,
      Periodicity: params.periodicity,
      Email: params.email,
      FileType: params.fileType,
      Days: params.days,
    }).then((data) => {
      if (data.Success) {
        commit('notify/addNotify', {
          massage: 'Отчёт запланирован',
          type: 'success',
        }, { root: true });
      } else {
        commit('notify/addNotify', {
          massage: `Ошибка ${data.Error}`,
          type: 'danger',
        }, { root: true });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания расписания`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  async updateReportSchedule ({ commit }, { id, params }) {
    await this.$api.$put(`/v1/Reporting/Schedule/${id}`, {
      ReportType: params.id,
      ObjectIds: params.objects,
      Parameters: params.parameters,
      AccountingUnitId: 0,
      PeriodType: params.periodType,
      PeriodValue: params.periodValue,
      Periodicity: params.periodicity,
      Email: params.email,
      FileType: params.fileType,
      Days: params.days,
    }).then((data) => {
      if (data.Success) {
        commit('notify/addNotify', {
          massage: 'Расписание изменено',
          type: 'success',
        }, { root: true });
      } else {
        commit('notify/addNotify', {
          massage: `Ошибка ${data.Error}`,
          type: 'danger',
        }, { root: true });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения расписания`,
        type: 'danger',
      }, { root: true });
    });
  },

  reportScheduleSuspend ({ commit }, id) {
    return this.$api.$put(`/v1/Reporting/Suspend/${id}`).then((data) => {
      if (data.Success) {
        return true;
      }
      commit('notify/addNotify', {
        massage: `Ошибка ${data.Error}`,
        type: 'danger',
      }, { root: true });
      return false;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка остановки выполнения расписания`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },

  reportScheduleResume ({ commit }, id) {
    return this.$api.$put(`/v1/Reporting/Resume/${id}`).then((data) => {
      if (data.Success) {
        return true;
      }
      commit('notify/addNotify', {
        massage: `Ошибка ${data.Error}`,
        type: 'danger',
      }, { root: true });
      return false;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка возобновления выполнения расписания`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },

  reportScheduleDelete ({ commit }, id) {
    return this.$api.$delete(`/v1/Reporting/Schedule/${id}`).then((data) => {
      if (data.Success) {
        return true;
      }
      commit('notify/addNotify', {
        massage: `Ошибка ${data.Error}`,
        type: 'danger',
      }, { root: true });
      return false;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления расписания`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
};
